/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

export default function CookieSettings({ text }) {
  return (
    <button sx={{ variant: "button.primary" }} className="ot-sdk-show-settings">
      {text}
    </button>
  )
}

CookieSettings.propTypes = {
  text: PropTypes.string.isRequired,
}
