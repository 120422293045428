/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"

import Variant from "@components/Variant"
import CookieList from "@components/OneTrust/CookieList"
import CookieSettings from "@components/OneTrust/CookieSettings"

export default function CookiesSE() {
  return (
    <Fragment>
      <Variant
        as="section"
        sx={{ maxWidth: 850, mx: "auto" }}
        variants={["container", "containerSpacing"]}
      >
        <div
          sx={{
            "h1, h2, h3, h4, h5, h6": { textAlign: "left", mt: 12 },
            p: { my: 5 },
          }}
        >
          <h1 sx={{ variant: "richtext.h1" }}>Cookies</h1>

          <h2 sx={{ variant: "richtext.h2" }}>Hur använder Brocc cookies?</h2>
          <p sx={{ variant: "richtext.p" }}>
            Vi använder information från cookies för att göra vår webbplats mer
            användarvänlig och för att göra det möjligt för oss att
            tillhandahålla ett anpassat innehåll. Vi använder oss även av ett
            antal auktoriserade tredje parter som, i vårt ställe, sätter ut
            cookies på vår webbplats för att leverera tjänster som de
            tillhandahåller.
          </p>
          <p sx={{ variant: "richtext.p" }}>
            Cookies kan bland annat innehålla användarinställningar, information
            om vilken typ av innehåll och vilka annonser som du har tagit del av
            på vår webbplats. Information som lagras kan vara upplysningar om
            hur du har surfat på och använt vår webbplats, vilken webbläsare du
            har använt, vilka annonser du har sett och hur du har surfat mellan
            de olika webbplatserna som vi samarbetar med. Cookies från externa
            leverantörer kan sättas för att utföra mätning av trafik, förenkla
            annonshanteringen och för att förbättra webbplatsens funktionalitet.
            Vi kan också använda cookies från externa leverantörer för att
            förenkla bannerannonsering på vår webbplats. Sådana cookies spårar
            aktiviteten på vår webbplats och används bland annat för att anpassa
            annonserbjudanden till dig.
          </p>
          <p sx={{ variant: "richtext.p" }}>
            När du använder vår webbplats kan även tredje parter placera cookies
            i din webbläsare i syfte att mäta resultatet av våra kunders
            bannerannonsering. För reglera tredje parters användning av cookies
            har Brocc satt upp en policy för hur data från vår webbplats får
            hanteras. Av policyn framgår att cookies eller andra
            spårningsmetoder inte får användas för att samla in data avseende
            användare av webbplatsen i syfte att använda denna för att annonsera
            mot användarna eller i annat kommersiellt syfte kategorisera
            användarna. Tredje parter får endast via cookies eller andra
            spårningsmetoder samla in anonym data i syfte att rapportera och
            följa upp bannerannonser antal visningar, räckvidd och konvertering.
          </p>

          <CookieList />
          <CookieSettings text="Inställningar för cookies" />

          <h2 sx={{ variant: "richtext.h2" }}>
            Syftet med Broccs användning av cookies
          </h2>
          <ul sx={{ variant: "richtext.ul" }}>
            <li sx={{ variant: "richtext.li" }}>
              Låta vår webbplats känna igen återkommande användare för att kunna
              anpassa tjänsterna
            </li>
            <li sx={{ variant: "richtext.li" }}>
              Tillåta användning av särskilda funktioner såsom möjligheten att
              spara annonser och bevakningar
            </li>
            <li sx={{ variant: "richtext.li" }}>
              Underlätta för användaren att röra sig på webbplatsen
            </li>
            <li sx={{ variant: "richtext.li" }}>
              Beräkna och rapportera användarantal och trafik
            </li>
            <li sx={{ variant: "richtext.li" }}>
              Utveckla och förbättra webbplatsen genom att bättre förstå hur den
              används
            </li>
            <li sx={{ variant: "richtext.li" }}>
              Bygga segment och målgrupper i marknadsföringssyfte och för att
              kunna rikta reklam
            </li>
            <li sx={{ variant: "richtext.li" }}>
              Minska reklam som är irrelevant för vissa målgrupper
            </li>
          </ul>

          <h2 sx={{ variant: "richtext.h2" }}>
            Hur ser jag vilka cookies som lagras
          </h2>
          <p sx={{ variant: "richtext.p" }}>
            Webbläsare sparar i normala fall alla cookies i en viss katalog på
            datorns hårddisk. Ett sätt att få veta vilka cookies som lagrats på
            din dator är att gå igenom på den katalogen och undersöka
            innehållet. Sessionskakor behöver dock inte lagras på hårddisken, så
            du kan inte vara säker på att hitta dem med denna metod.
          </p>

          <h2 sx={{ variant: "richtext.h2" }}>Hur väljer jag bort cookies?</h2>
          <p sx={{ variant: "richtext.p" }}>
            Du kan själv välja om du vill acceptera att ta emot cookies eller
            inte. Genom att det kommunikationsmedel som du använder dig av är
            inställt på sådant sätt att cookies tillåts samtycker du till att ta
            emot cookies från vår webbplats. Du har möjlighet att ställa in om
            du vill att cookies ska få sparas automatiskt i ditt
            kommunikationsmedel, om du vill tillfrågas varje gång en cookie vill
            sparas eller om du inte vill att några cookies ska sparas. Detta gör
            du genom att ändra inställningarna i din webbläsare så att den inte
            längre tillåter cookies. Hur du gör detta beror på vilken typ av
            webbläsare som du använder dig av. Du behöver följa samma process på
            alla webbläsare du använder på samma och/eller olika datorer eller
            enheter. Om du väljer att stänga av cookies kommer det hindra delar
            av vår webbplats från att fungera korrekt. Bland annat kommer du
            inte att kunna logga in på ditt konto och du kommer inte heller att
            få anpassade annonsrekommendationer. För mer detaljerad information
            om cookies kan du besöka{" "}
            <a rel="noreferrer noopener" href="https://www.minacookies.se">
              www.minacookies.se
            </a>{" "}
            eller{" "}
            <a rel="noreferrer noopener" href="https://www.pts.se">
              www.pts.se
            </a>
            .
          </p>

          <h2 sx={{ variant: "richtext.h2" }}>Kontaktinformation</h2>
          <p sx={{ variant: "richtext.p" }}>
            Om du har frågor om hur Brocc använder cookies ber vi dig kontakta
            vår Kundservice på <a href="mailto:help@brocc.se">help@brocc.se</a>.
            Du kan även läsa om{" "}
            <a href="https://brocc.se/docs/integrity-policy-pdf">
              vår integritetspolicy här
            </a>
            .
          </p>
        </div>
      </Variant>
    </Fragment>
  )
}
