/** @jsx jsx */
import { jsx } from "theme-ui"

import CookiesSE from "../../components/Cookies/CookiesSE"

// Currently there is no English version of the cookies page,
// therefore the Swedish page is used
export default function CookiesPageEN() {
  return <CookiesSE />
}
